import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LanguageService {

  languagesKeys: string[] = ['en', 'fr'];
  currentLanguage!: string;

  constructor(private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document) { }

  public init(): void {
    this.translateService.addLangs(this.languagesKeys);

    this.currentLanguage = localStorage.getItem('currentLanguage') ?? environment.defaultLanguage;

    this.translateService.use(this.currentLanguage);
  }

  public setLanguage(langKey: string) {
    this.currentLanguage = langKey;
    this.translateService.use(langKey);
    localStorage.setItem('currentLanguage', langKey);
  }

}
